@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
    scroll-behavior: smooth;
}

body > #root {
    height: 100vh;
}

*,
:after,
:before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
}

.Toastify__toast {
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0rem;
}

.Toastify__toast--default {
    color: #2a2a2f;
}

.Toastify__toast--success {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/proxima_nova_light.woff2) format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/proxima_nova_regular.woff2) format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/proxima_nova_semibold.woff2) format('woff2');
    font-weight: 600;
}

@font-face {
    font-family: 'ProximaNova';
    src: url(./fonts/proxima_nova_bold.woff2) format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Univers';
    src: url(./fonts/univers_light.woff2) format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'Univers';
    src: url(./fonts/univers_55_roman.woff2) format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'Univers';
    src: url(./fonts/univers_65_bold.woff2) format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Univers';
    src: url(./fonts/univers_black.woff2) format('woff2');
    font-weight: 900;
}

.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.ProseMirror-focused {
    outline: none;
}

.ProseMirror {
    min-height: 90px;
}

.job-comment-form .ProseMirror {
    min-height: 44px;
}

.prose p {
    min-height: 16px !important;
}

.rs__input {
    box-shadow: var(--tw-ring-inset) 0 0 0
        calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}

:root {
    --swiper-theme-color: #3b82f6 !important;
}

.ProseMirror svg g {
    fill: currentColor;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 10;
}
.rs__input {
    box-shadow: var(--tw-ring-inset) 0 0 0
        calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}

.custom-swiper-style {
    padding-bottom: 2rem !important;
}

.custom-swiper-style .swiper-pagination {
    bottom: 5px !important;
}

.custom-swiper-style .swiper-button-next,
.custom-swiper-style .swiper-button-prev {
    transform: translateY(-50%);
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}
.player-wrapper .custom-react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.search {
    position: relative;
}

.search button,
.search span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.search > * > svg {
    display: block;
}

.search span {
    left: 20px;
    opacity: 0.5;
    margin-top: 1px;
}

.search button {
    right: 20px;
    left: auto;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    color: #333;
}

.search button:hover {
    color: #f48fb1;
}

.search input {
    box-sizing: border-box;
    display: block;
    outline: none;
    background: white;
}

.twitter_logo:hover {
    filter: invert(48%) sepia(85%) saturate(572%) hue-rotate(163deg)
        brightness(96%) contrast(98%);
}
.twitch_logo:hover {
    filter: invert(50%) sepia(71%) saturate(7453%) hue-rotate(250deg)
        brightness(102%) contrast(101%);
}
.discord_logo:hover {
    filter: invert(73%) sepia(67%) saturate(4616%) hue-rotate(201deg)
        brightness(92%) contrast(84%);
}
.instagram_logo:hover {
    filter: invert(37%) sepia(51%) saturate(6070%) hue-rotate(314deg)
        brightness(87%) contrast(98%);
}
.youtube_logo:hover {
    filter: invert(19%) sepia(98%) saturate(5544%) hue-rotate(360deg)
        brightness(92%) contrast(123%);
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .custom-scrollbar::-webkit-scrollbar-track,
    .custom-scrollbar-gray::-webkit-scrollbar-track {
        border-radius: 10px;
        margin: 10px 0;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 7px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #000;
    }

    .custom-scrollbar-gray::-webkit-scrollbar {
        height: 4px;
    }

    .custom-scrollbar-gray::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #777777;
    }
}

.slot-chip {
    min-width: 70px;
}

.office-hours__wrapper {
    max-height: 350px;
    overflow-y: scroll;
}

.slot-selected {
    background-color: grey;
}

.slick-slider .slick-list .slick-track {
    max-height: 0px;
}

.wordmark-logo {
    animation: wordmark-color-change infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 4s;
}

@keyframes wordmark-color-change {
    0% {
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        fill: #48c96c;
    }
    20% {
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        fill: #ffa767;
    }
    60% {
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        fill: #fc6adc;
    }
    100% {
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        fill: #48c96c;
    }
}

.navigation {
    @apply px-4 -ml-2 h-12 pt-1 text-[13px] lg:text-lg rounded-md font-normal flex items-center justify-start;
}

.navigation-active {
    @apply bg-white text-gray-900 border-[2px]  border-black rounded-full;
}

.navigation-inactive {
    @apply hover:bg-gray-50 hover:text-gray-900 rounded-full;
}

.sticky-rightside-content {
    @apply lg:top-6 lg:flex lg:flex-col lg:items-start lg:justify-start;
}

.rightside-searchbox {
    @apply appearance-none inline-flex w-full px-2 py-1 border border-gray-300 bg-warm-gray-200 rounded-full shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm;
}

.rightside-box {
    @apply max-h-72 rounded-2xl w-full mt-4;
}

.tab {
    @apply px-3 pb-4 text-sm flex cursor-pointer font-bold w-full items-center justify-center transition-all duration-300;
}

/* settings  */
.setting-header {
    @apply w-full h-16 flex items-center justify-between border-b px-4 font-bold text-2xl text-black;
}

.settings-list {
    @apply p-5 py-6 text-base leading-5 font-medium text-gray-900 hover:bg-warm-gray-100 cursor-pointer flex items-center justify-between;
}

.settings-list-active {
    @apply bg-warm-gray-100;
}

.setting-right-arrow {
    @apply hidden;
}

.settings-list-active .setting-right-arrow {
    @apply block;
}

.ais-SearchBox-input {
    border: none;
}
